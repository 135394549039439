body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.parallex-header {
  background: #000;
  background: linear-gradient(rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)),
    url(./assets/4.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;

}

.header-content {
  padding: 100px 0 150px 0;

}

/* Offcanvas Sidebar */
.offcanvas-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  background: #ffffff;
  color: #000;
  z-index: 1050;
  padding: 20px;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}

.offcanvas-content {
  display: flex;
  flex-direction: column;
}

.close-btn {
  align-self: flex-end;
  font-size: 20px;
  border: none;
  background: transparent;
  color: #000;
  cursor: pointer;
  margin-bottom: 10px;
}